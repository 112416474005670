import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { API_CONSTANTS, COOKIES, TIME } from '@app/app.constants';
import { AuthToken } from '@interfaces/auth-token';
import { ConfigService } from '@app/core/config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {
    webApiUrl: string;

    constructor(
        private config: ConfigService,
        private cookieService: CookieService,
        private http: HttpClient
    ) {
        this.webApiUrl = this.config.getValue('webApiUrl');
    }

    /**
     * Gets an auth cookie
     * @param version   api version (optional - defaults to v1)
     */
    getAuthToken(baseUrl = this.webApiUrl): Observable<AuthToken|boolean> {
        const url = `${baseUrl}${API_CONSTANTS.AUTHZ_ENDPOINT}`;

        if (this.isPublicTokenValid()) {
            return of(true);
        }

        return this.http.post(url, {})
            .pipe(map((response: AuthToken) => {
                if (response && response.result && response.result.expires_in) {
                    this.setTokenCookie(response.result.expires_in);
                }

                return response;
            }));
    }

    /**
     * Sets a cookie that will expire 1 min before the
     * actual token expires.
     * @param expireTime expireTime in seconds.
     */
    private setTokenCookie(expireTime: string): void {
        let expireIn = Number(expireTime);
        // expire the token 1 min sooner
        // to make sure we renew the token at a safe moment.
        expireIn = expireIn > TIME.SECONDS ? expireIn - TIME.SECONDS : expireIn;

        let expireDate = new Date().getTime();
        expireDate += expireIn * TIME.MILLISECONDS;

        this.cookieService.set(
            COOKIES.publicTokenCookieName,
            expireDate.toString(),
            null, // this cookie will expire with the browser session
            '/'
        );
    }

    /**
     * removes the token cookie
     */
    removeTokenCookie(): void {
        this.cookieService.delete(COOKIES.publicTokenCookieName, '/');
    }

    /**
     * Checks if the current token is expired
     * based on the existence of the token cookie.
     * @returns boolean
     */
    isPublicTokenValid(): boolean {
        let isValid = false;
        const expireTimeCookie = this.cookieService.get(COOKIES.publicTokenCookieName);

        if (expireTimeCookie) {
            isValid = Number(expireTimeCookie) - new Date().getTime() > 0;
            if (!isValid) {
                // remove the cookie if token is expired.
                this.removeTokenCookie();
            }
        }

        return isValid;
    }
}
