import { Component, OnInit, Input } from '@angular/core';
import { ParkHoursService } from '@app/services/park-hours/park-hours.service';
import { ConfigService } from '@core/config.service';

@Component({
  selector: 'park-hours',
  templateUrl: './park-hours.component.html',
  styleUrls: ['./park-hours.component.scss']
})

export class ParkHoursComponent implements OnInit {
  @Input() analyticsTrackingEvent;
  siteId;
  parkHoursConfig;
  parkHoursItems;
  parksAvailable = false;
  panelHeadingText = 'Today\'s Park Hours';
  isFast = false;
  constructor(
    private parkHoursService: ParkHoursService,
    private configService: ConfigService
  ) {
    this.siteId = this.configService.getValue('siteId').toLowerCase();
    console.log('park hour constructor');
  }

  async ngOnInit() {
    try {
      this.parkHoursConfig = await this.parkHoursService.getParkHours();
      this.parkHoursItems = this.parkHoursConfig.content || [];
      this.panelHeadingText = this.parkHoursConfig?.config?.panelHeadingText || this.panelHeadingText;
      this.parksAvailable = true;
    } catch(error) {
      console.error('Error fetching park hours data: ', error);
    }
    if (document.body.classList.contains('showfast')) {
      document.getElementById('park-hours-container').classList.add('showfast');
      this.isFast = true;
    }
  }

  onToggleClick = () => {
    const toggleIcon = document.getElementById('park-hours-icon-available');
    const container = document.getElementById('park-hours-ul-available');
    const toggleButton = document.getElementById('park-hours-toggle-button');

    if (toggleIcon.classList.contains('open')) {
      toggleIcon.classList.remove('open');
      container.classList.remove('show-park-hours');
      toggleButton.setAttribute('aria-expanded', "false");
    } else {
      if (this.siteId) {
        this.analyticsTrackingEvent(`${this.siteId}_home_parkhours_expand`);
      }
      toggleIcon.classList.add('open');
      container.classList.add('show-park-hours');
      toggleButton.setAttribute('aria-expanded', "true");
    }
  }

}
