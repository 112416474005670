import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@app/core/config.service';
import getCookie from '@app/shared/utils/string/getCookie';

const destinationTypes = ['4-destination-hero', '5-destination-hero', '6-destination-hero'] as const;
declare const window: any;
type DestinationType = typeof destinationTypes[number];

const fastDestinationTypes = ['4-fast-destination-hero'] as const;
type FastDestinationType = typeof fastDestinationTypes[number];

@Component({
    selector: 'pixie-stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StoriesComponent implements OnInit {
    @Input() analyticsTrackingEvent = (linkId, {}) => {}; //Only passed in for WDW, for other brands defaulting to empty function
    siteId = this.configService.getValue('siteId');
    desktopOrMobile = 'desktop';
    storyOrder;
    stories: any[];

    constructor(
        private resourceBundle: ResourceBundleService,
        private configService: ConfigService,
    ) { }

    getDestinationTemplate(name: string): DestinationType | undefined {
        if (this.isDestinationTemplate(name)) {
            return name;
        }
    }

    getFastDestinationTemplate(name: string): FastDestinationType | undefined {
        if (this.isFastDestinationTemplate(name)) {
            return name;
        }
    }

    getStoryId(generalPurposeStrings, index): string {
        return `${generalPurposeStrings.storyName}-${index}`;
    }

    isDestinationTemplate(str: string): str is DestinationType {
        return destinationTypes.includes(str as any);
    }

    isFastDestinationTemplate(str: string): str is FastDestinationType {
        return fastDestinationTypes.includes(str as any);
    }

    ngOnInit() {
        this.resourceBundle.get('stories').then(data => {
            if (data.storyOrderingPreset) {
                this.storyOrder = data.storyOrderingPreset[this.desktopOrMobile];
            }
            this.stories = data.stories;

            //in order to see the FaST stories set this cookie: document.cookie = 'toggleOnFast=true';
            if (getCookie('toggleOnFast') || document.body.classList.contains('showfast')) {
                document.body.classList.add('showfast');
                for (var i = 0; i < this.stories.length; i++) {
                    if (/(fast)/i.test(this.stories[i].name)) {
                        var firstFastStory = this.stories[i].generalPurposeStrings.template;
                        var checkLineBreak = setInterval(function() {
                            const hr = document.getElementsByClassName('template-' + firstFastStory)[0].getElementsByTagName('hr')[0];
                            if (hr) {
                                clearInterval(checkLineBreak);
                                hr.classList.add('show-mobile');
                                document.getElementsByClassName('template-' + firstFastStory)[0].classList.add('tablet-margin');
                            }
                        }, 100);
                        break;
                    }
                }
            }
            // flag prerender is ready in 5 seconds
            setTimeout (() => {
                window.prerenderReady = true;
           }, 5000);
        });
    }

}
